import { Controller } from "@hotwired/stimulus"
import {enter, leave} from 'el-transition';

export default class extends Controller {
  static targets = ["content", "arrow"]

  connect() {
    // Ensure initial state matches content visibility
    // this.contentTargets.forEach((content, index) => {
    //   if (!content.classList.contains('hidden')) {
    //     this.arrowTargets[index].classList.add('rotate-180')
    //   }
    // })
  }

  toggle(event) {
    event.preventDefault()
    const clickedContent = event.currentTarget.nextElementSibling
    const clickedArrow = event.currentTarget.querySelector('[data-accordion-target="arrow"]')
    
    

    // Toggle clicked accordion item
    if (clickedContent.classList.contains('hidden')) {
      // Close all other accordion items first
      this.contentTargets.forEach((content, index) => {
        if (content !== clickedContent && !content.classList.contains('hidden')) {
          this.arrowTargets[index].classList.remove('rotate-180')
          leave(content).then(() => {
          })
        }
      })
      clickedArrow.classList.add('rotate-180')
      enter(clickedContent).then(() => {
      })
    } else {
      clickedArrow.classList.remove('rotate-180')
      leave(clickedContent).then(() => {
      })
    }
  }

}