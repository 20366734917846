import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["border", "label"];

  initialize() {
    console.log("initializing radio!");
  }

  connect() {
    console.log("radio connect!");
  }

  change(e) {
    // Remove green styling from all radio labels and borders
    this.labelTargets.forEach(label => {
      label.classList.add("border-gray-300");
      label.classList.remove("border-green-600", "ring-2", "ring-green-600");
    });
    this.borderTargets.forEach(border => {
      border.classList.add("border-transparent");
      border.classList.remove("border-green-600");
    });

    // Add green styling only to the selected radio
    const checked = e.currentTarget.checked;
    if (checked) {
      const label = e.currentTarget.closest('[data-target="radio.label"]');
      const border = label.querySelector('[data-target="radio.border"]');
      
      label.classList.remove("border-gray-300");
      label.classList.add("border-green-600", "ring-2", "ring-green-600");
      border.classList.remove("border-transparent"); 
      border.classList.add("border-green-600");
    }
  }
}