import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["jsonInput", "iframe", "form", "modal", "elementHTMLInput", "elementId"];

  connect() {
    window.addEventListener('message', this.handleMessage.bind(this));
    this.snapshotReceived = false;
  }

  handleMessage(event) {
    if (event.data.type === 'demoground:text_changed') {
      // Handle text changes from editable elements
      console.log('Text changed:', event.data.element_id, event.data.text);
    } else if (event.data.type === 'demoground:static_json_snapshot:complete') {
      // Handle snapshot data
      if (this.hasJsonInputTarget) {
        this.jsonInputTarget.value = JSON.stringify(event.data.snapshot);
        this.snapshotReceived = true;
        if (this.hasFormTarget) {
          this.formTarget.requestSubmit();
        }
      }
    } else if (event.data.type === "demoground:edit_content:element") {
      if (this.hasElementHTMLInputTarget) {
        this.elementHTMLInputTarget.value = event.data.elementHtml;
      }
      if (this.hasElementIdTarget) {
        this.elementIdTarget.textContent = event.data.elementId;
      }
      this.selectedElementId = event.data.elementSelectorId;
      const modalController = this.application.getControllerForElementAndIdentifier(
        this.element,
        'modal'
      );
      const aceHtmlController = this.application.getControllerForElementAndIdentifier(
        this.modalTarget,
        'ace-html'
      );
      aceHtmlController.load();
      modalController.toggle();
    }
  }

  saveHtmlEdit(e) {
    e.preventDefault();
    const aceHtmlController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      'ace-html'
    );
    aceHtmlController.submit();
    if (this.hasElementHTMLInputTarget && this.hasIframeTarget) {
      const html = this.elementHTMLInputTarget.value;
      
      this.iframeTarget.contentWindow.postMessage({
        type: 'demoground:edit_content:update',
        elementId: this.selectedElementId,
        html: html
      }, '*');

      const modalController = this.application.getControllerForElementAndIdentifier(
        this.element,
        'modal'
      );
      modalController.toggle();
    }
  }

  disconnect() {
    window.removeEventListener('message', this.handleMessage.bind(this));
  }

  snapshotContent(e) {
    if(this.snapshotReceived == true) return;
    e.preventDefault();
    //Send the postmessage to the iframe
    if (this.hasIframeTarget) {
      this.iframeTarget.contentWindow.postMessage({
        type: 'demoground:static_json_snapshot'
      }, '*');
    }

  }
}