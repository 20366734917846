import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["iframe"]

  connect() {
    // Initial scale
    this.scaleIframe()

    // Re-scale on window resize
    window.addEventListener('resize', () => this.scaleIframe())
  }

  scaleIframe() {
    // const iframe = this.iframeTarget
    // const parent = iframe.parentElement

    // const scaleX = parent.clientWidth / iframe.offsetWidth
    // const scaleY = parent.clientHeight / iframe.offsetHeight
    // const scale = Math.min(scaleX, scaleY)
    
    // iframe.style.transform = `scale(${scale})`
    // iframe.style.transformOrigin = 'top left'
  }
}
