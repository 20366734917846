import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectorInput", "button", "widgetHtmlInput"]

  connect() {
    this.iframe = document.getElementById(this.element.dataset["frame"]);
    window.addEventListener("message", this.messageHandler.bind(this));
  }

  messageHandler(e) {
    try{
      if(e.data.type == "demoground:hotspot:element") {
        this.selectorInputTarget.value = e.data.selector;
        this.widgetHtmlInputTarget.value = e.data.widgetHtml;
        button.disabled = false;
        button.textContent = "Choose Element";
      }
    } catch(e) {
      
    }
  }

  initiateSelector(e) {
    e.preventDefault();
    console.log("INITIATE!");
    const button = e.currentTarget;
    button.disabled = true;
    button.textContent = "Choose the element within the widget";
    this.iframe.contentWindow.postMessage({ type: "demoground:hotspot:chooseElement", index: this.element.dataset["widgetindex"] }, "*")
  }
}
