import "@hotwired/turbo-rails"

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import Notification from '@stimulus-components/notification'
import { Autocomplete } from 'stimulus-autocomplete'
import TreeController from '@kanety/stimulus-tree';

// import Dropdown from 'stimulus-dropdown'
import { Tabs, Modal, Popover, Dropdown, Toggle } from "tailwindcss-stimulus-components"

const application = Application.start()
// application.register('tabs', Tabs)
application.register('dropdown', Dropdown)
application.register('toggle', Toggle)
application.register('popover', Popover)
application.register('tabs', Tabs)
application.register('tree', TreeController)

// application.register('modal', Modal)
application.register('notification', Notification)
application.register('autocomplete', Autocomplete)

// Configure Stimulus development experience
application.debug = false
const context = require.context("controllers/", true, /_controller\.js$/)

application.load(definitionsFromContext(context))
window.Stimulus = application
// application.register('dropdown', Dropdown)

export { application }

import "../utils/helpers"
import "../controllers"
// Support component names relative to this directory:
document.addEventListener("turbolinks:load", function() {
  window.scrollTo(0,0)
});

document.addEventListener('turbo:before-cache', function () {
  application.controllers.forEach(function (controller) {
    try {
      if (typeof controller.disconnect === 'function') {
        controller.disconnect()
      }
    } catch (_) {}
  })
})

// Alternative: Add this in the connect function of the stimulus controller.
document.addEventListener('turbo:before-cache', function () {
  this.disconnect();
})