import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.addEventListener("message", this.handleMessage.bind(this))
  }

  disconnect() {
    window.removeEventListener("message", this.handleMessage.bind(this))
  }

  handleMessage(event) {
    if (event.data.type === "demoground:visit") {
      const url = event.data.location
      if(event.data.turbo_frame) {
        Turbo.visit(url, { frame: event.data.turbo_frame })
        return
      }
    }
  }
}
