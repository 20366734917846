import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "add", "body"]
  static values = {
    wrapperSelector: String
  }

  connect() {
    // Optional: Add any initialization logic here
  }

  add(event) {
    event.preventDefault()
    
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.bodyTarget.insertAdjacentHTML('beforeend', content)
  }

  remove(event) {
    event.preventDefault()
    
    const wrapper = event.target.closest(this.wrapperSelectorValue)
    
    // New records can be removed directly
    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove()
    }
    // Existing records need to be marked for deletion
    else {
      wrapper.style.display = "none"
      const input = wrapper.querySelector("input[name*='_destroy']")
      input.value = "1"
    }
  }
}
