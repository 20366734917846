import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ["loader", "autocomplete", "urlInput", "emailInput"];

  connect() {    
    this.autocompleteTarget.addEventListener("autocomplete.change", (e) => {
      this.change(e);
    });

    ["loadstart", "loadend"].forEach(evt => {
      this.autocompleteTarget.addEventListener(evt, (e) => {
        this.loaderTarget.classList.toggle("hidden");
      });
    });
  }
  change(e) {
    const selected = e.detail.selected
    const customer = JSON.parse(selected.dataset["autocompleteData"]);
    this.urlInputTarget.value = customer.shopifyDomain || customer.domain;
    this.emailInputTarget.value = customer.email;
  }
}
