import { Controller } from "@hotwired/stimulus"
import { fetchSessionFromMantle } from  "../utils/helpers"
export default class extends Controller {
  initialize() {
    console.log("initializing mantle!");
  }

  connect() {
    window.addEventListener("message", this.messageHandler.bind(this));
    this.loadPage();
    this.setupMantleToken();
    setInterval(() => {
      this.setupMantleToken();
    }, 60 * 1000);
  }

  messageHandler(e) {
    if(e.origin != this.element.dataset["targetOrigin"]) return;
    try {
      // Handle message
      const data = e.data;
      if(data.type == 'mantle:bridge:action') {
        switch(data.action) {
          case 'newDemo':
            this.modalController.toggle();
            break;
          
          default:
            console.log("Unknown action:", data.action);
        }
      } else if(data.type == 'mantle:bridge:closeModal') {
        this.modalController.closeWithoutMantle();
      }
    } catch(e) {
      console.error(e);
    }
  }

  loadPage() {
    const message = {
      type: "mantle:bridge:setPage", 
      title: this.element.dataset["title"], 
      subtitle: this.element.dataset["subtitle"] 
    }
    if(this.element.dataset.primaryAction) message.primaryAction = JSON.parse(decodeURIComponent(this.element.dataset.primaryAction.replace(/\+/g, ' ')));
    parent.postMessage(
      message, 
        this.element.dataset["targetOrigin"])
  }
  setupMantleToken() {
    fetchSessionFromMantle(this.element.dataset["targetOrigin"]).then((session) => {
      console.log("SESSION!")
      console.log(session);
      window.sessionToken = session.session;
      document.addEventListener("turbolinks:request-start", function (event) {
        var xhr = event.data.xhr;
        xhr.setRequestHeader("Authorization", "Bearer " + window.sessionToken);
      });
      
      document.addEventListener("turbolinks:render", function () {
        $("form, a[data-method=delete]").on("ajax:beforeSend", function (event) {
          const xhr = event.detail[0];
          xhr.setRequestHeader("Authorization", "Bearer " + window.sessionToken);
        });
      });
      // All xhr requests
      XMLHttpRequest.prototype.open = (function(open) {
        return function(method,url,async) {
          open.apply(this,arguments);
          this.setRequestHeader('Authorization', "Bearer " + window.sessionToken);
          };
      })(XMLHttpRequest.prototype.open);

      var originalFetch = window.fetch;
      window.fetch = function (input, init) {
          if (!init) {
              init = {};
          }
          if (!init.headers) {
              init.headers = new Headers();
          }

          // init.headers could be: 
          //   `A Headers object, an object literal, 
          //    or an array of two-item arrays to set request’s headers.`
          if (init.headers instanceof Headers) {
              init.headers.append('Authorization', "Bearer " + window.sessionToken);
          } else if (init.headers instanceof Array) {
              init.headers.push(['Authorization', "Bearer " + window.sessionToken]);
          } else {
              // object ?
              init.headers['Authorization'] = "Bearer " + window.sessionToken;
          }
          return originalFetch(input, init);
      };
    });
  }
  get modalController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "mantle-modal");
  }
}