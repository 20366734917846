import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["newCustomer", "mantleCustomer"]

  initialize() {
  }

  change(e) {
    this.newCustomerTarget.classList.toggle("hidden");
    this.mantleCustomerTarget.classList.toggle("hidden");
  }
}
