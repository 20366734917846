export function titleize(sentence) {
  if(!sentence.split) return sentence;
  var _titleizeWord = function(string) {
          return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      },
      result = [];
  sentence.split(" ").forEach(function(w) {
      result.push(_titleizeWord(w));
  });
  return result.join(" ");
}

export function isMobile() {
  //console.log("Navigator: " + navigator.userAgent);
  return /(iphone|ipod|ipad|android|blackberry|windows ce|palm|symbian)/i.test(navigator.userAgent);
};
export function hexToRgbaBg(hex) {
var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
return "rgba(" + parseInt(result[1], 16) + ", " + parseInt(result[2], 16) + ", " + parseInt(result[3], 16) + ", 0.1)";
}

export function isDark(hexcolor){
var r = parseInt(hexcolor.substr(1,2),16);
var g = parseInt(hexcolor.substr(3,2),16);
var b = parseInt(hexcolor.substr(4,2),16);
var yiq = ((r*299)+(g*587)+(b*114))/1000;
// Return new color if to dark, else return the original
return yiq < 128;
}
export function insertUrlParam(key, value) {
if (history.pushState) {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
    window.history.pushState({path: newurl}, '', newurl);
}
}
export function serialize (form) {
if (!form || form.nodeName !== "FORM") {
        return;
}
var i, j, q = [];
for (i = form.elements.length - 1; i >= 0; i = i - 1) {
    if (form.elements[i].name === "") {
        continue;
    }
    switch (form.elements[i].nodeName) {
        case 'INPUT':
            switch (form.elements[i].type) {
                case 'text':
                case 'tel':
                case 'email':
                case 'hidden':
                case 'password':
                case 'button':
                case 'reset':
                case 'submit':
                    q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
                    break;
                case 'checkbox':
                case 'radio':
                    if (form.elements[i].checked) {
                            q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
                    }                                               
                    break;
            }
            break;
            case 'file':
            break; 
        case 'TEXTAREA':
                q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
                break;
        case 'SELECT':
            switch (form.elements[i].type) {
                case 'select-one':
                    q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
                    break;
                case 'select-multiple':
                    for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
                        if (form.elements[i].options[j].selected) {
                                q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].options[j].value));
                        }
                    }
                    break;
            }
            break;
        case 'BUTTON':
            switch (form.elements[i].type) {
                case 'reset':
                case 'submit':
                case 'button':
                    q.push(form.elements[i].name + "=" + encodeURIComponent(form.elements[i].value));
                    break;
            }
            break;
        }
    }
return q.join("&");
}


export function getMetaValue(name) {
const element = findElement(document.head, `meta[name="${name}"]`)
if (element) {
  return element.getAttribute("content")
}
}

export function findElement(root, selector) {
if (typeof root == "string") {
  selector = root
  root = document
}
return root.querySelector(selector)
}

export function removeElement(el) {
if (el && el.parentNode) {
  el.parentNode.removeChild(el);
}
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}
export function replaceUrlParam(url, paramName, paramValue)
{
    if (paramValue == null) {
        paramValue = '';
    }
    var pattern = new RegExp('\\b('+paramName+'=).*?(&|#|$)');
    if (url.search(pattern)>=0) {
        return url.replace(pattern,'$1' + paramValue + '$2');
    }
    url = url.replace(/[?#]$/,'');
    return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
}

export const fetchSessionFromMantle = async (targetOrigin) => {
  return new Promise((resolve, reject) => {
    window.parent.postMessage(
      {
        type: "mantle:bridge:sessionRequest",
      },
      targetOrigin
    );

    const receiveSessionMessage = (event) => {
      if (event.origin !== targetOrigin) {
        reject(new Error("Invalid origin"));
        return;
      }

      try {
        if (event.data?.type === "mantle:bridge:sessionResponse") {
          if (event.data?.error) {
            reject(new Error("Failed to load session"));
          } else {
            resolve(event.data);
          }
          window.removeEventListener("message", receiveSessionMessage);
        }
      } catch (e) {
        console.error("Invalid data received:", event.data);
        reject(new Error("Failed to load session"));
      }
    };

    window.addEventListener("message", receiveSessionMessage);
  });
};

export function preventBackScroll() {
  if (!navigator.userAgent.match(/Macintosh/)) {
    return;
  }

  // Detect browsers
  var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
  var is_safari = navigator.userAgent.indexOf("Safari") > -1;
  var is_firefox = navigator.userAgent.indexOf('Firefox') > -1;

  // Handle scroll events in Chrome, Safari, and Firefox
  if (is_chrome || is_safari || is_firefox) {

    // TODO: This only prevents scroll when reaching the topmost or leftmost
    // positions of a container. It doesn't handle rightmost or bottom,
    // and Lion scroll can be triggered by scrolling right (or bottom) and then
    // scrolling left without raising your fingers from the scroll position.
    window.addEventListener('mousewheel', (e) => {
      let prevent_left, prevent_up;

      // If none of the parents can be scrolled left when we try to scroll left
      prevent_left = e.deltaX < 0 && !hasScrollableParent(e.target, 'horizontal');

      // If none of the parents can be scrolled up when we try to scroll up
      prevent_up = e.deltaY > 0 && hasScrollableParent(e.target, 'vertical');

      // Prevent futile scroll, which would trigger the Back/Next page event
      if (prevent_left || prevent_up) {
        e.preventDefault();
      }
    });

    function hasScrollableParent(element, direction) {
      while (element.parentElement) {
        const parent = element.parentElement;
        if (direction === 'horizontal' && parent.scrollLeft > 0) {
          return true;
        }
        if (direction === 'vertical' && parent.scrollTop > 0) {
          return true;
        }
        element = parent;
      }
      return false;
    }
  }
}